* {
  margin: 0;
  scroll-behavior: smooth;
}

.active {
  border-left: 5px solid #8b49df !important;
  background-color: #8a49df17 !important;
  color: #8b49df !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
